"use client";

import React, { Fragment, useState } from "react";
import { useParams, usePathname, useRouter } from "next/navigation";

import { Collapse, List, ListItemButton, ListItemText, ListItemIcon, Typography } from "@mui/material";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import LockRounded from "@mui/icons-material/LockRounded";

import { useEnvVariables } from "@/config/EnvVariablesProvider";
import { isQnb, isAub, isKbc } from "@/helpers/constants";
import { Routes } from "@/types";

export interface IDocsLink {
  title: string;
  url: string;
  secure?: boolean;
  adminLogin?: boolean;
  children?: IDocsLink[];
  expanded?: boolean;
}

export const documentationLinks = (
  isQnb: boolean,
  isKbcApp: boolean,
  isClientApp: boolean,
  hideRequestService: boolean,
): IDocsLink[] => [
  {
    title: "Getting Started",
    url: "",
    children: [
      { title: "Developer Onboarding", url: "developer_onboarding" },
      { title: "App Management", url: "developer_apps" },
      { title: "OAuth Authentication", url: "oauth_authentication" },
      { title: "User Management", url: "user-management", secure: true, adminLogin: false },
      ...(!(isQnb || isKbcApp || hideRequestService)
        ? [{ title: "API Specifications", url: "api-specifications", secure: true, adminLogin: false }]
        : []),
      { title: "SFTP File Transfer", url: "sftp-user-guide", secure: true, adminLogin: false },
      ...(!hideRequestService
        ? [{ title: "Bulk name check", url: "bulk-name-check", secure: true, adminLogin: true }]
        : []),
      ...(!hideRequestService
        ? [{ title: "Internal Developer Testing", url: "internal-testing", secure: true, adminLogin: false }]
        : []),
      ...(hideRequestService
        ? [{ title: "Internal Developer Testing", url: "internal-testing-inbound", secure: true, adminLogin: false }]
        : []),
      { title: "Responder Settings", url: "responder-settings", secure: true, adminLogin: true },
      ...(!hideRequestService
        ? [
            {
              title: "MI Report submission through CAMIS portal",
              url: "mi-report-submission",
              secure: true,
              adminLogin: true,
            },
          ]
        : []),
      { title: "OPTO account name check", url: "opto-accounts", secure: true, adminLogin: true },
    ],
    expanded: true,
  },
  {
    title: "Internal Developer",
    url: "",
    children: [
      ...(!isClientApp
        ? [
            {
              title: "Banfico Account Verification Service",
              url: "account-verification",
              children: [
                { title: "Verify Account Details", url: "account-verification#create-account-verification" },
                { title: "Fetch Result using ID", url: "account-verification#read-account-verification" },
              ],
              expanded: false,
            },
          ]
        : []),
      ...(isClientApp && !hideRequestService
        ? [{ title: "Banfico Account Verification Service", url: "account-verification-service" }]
        : []),
      ...(isQnb && !hideRequestService ? [{ title: "Confirmation of Payee Request Service", url: "outbound" }] : []),
      ...(isQnb && !hideRequestService ? [{ title: "Name Verification Using Bulk Upload", url: "bulk-upload" }] : []),
      ...(isQnb && !hideRequestService ? [{ title: "Fetch Records Using BatchID", url: "report" }] : []),
      ...(!isQnb && !hideRequestService
        ? [{ title: "Bulk Account Verifications", url: "bulk-account-verifications" }]
        : []),
      ...(!isQnb && !hideRequestService
        ? [{ title: "Get Result Using RefID", url: "bulk-account-verifications-by-ref-id" }]
        : []),
      ...(!hideRequestService ? [{ title: "MI Logging API", url: "mi-logging" }] : []),
      ...(!hideRequestService ? [{ title: "MI Report Download  API", url: "mi-report" }] : []),
      { title: "Fetch inbound transactions using TXN ID", url: "fetch-inbound-by-txn-id" },
      {
        title: "Account Management",
        url: "account-management",
        children: [
          { title: "Personal Account Management", url: "account-management#personal-account-management" },
          { title: "Business Account Management", url: "account-management#business-account-management" },
        ],
        expanded: false,
      },
      {
        title: "Account Management Using Bulk Upload API",
        url: "bulk-account-management",
        children: [
          {
            title: "Personal Account Management using Bulk Upload",
            url: "bulk-account-management#personal-bulk-account-management",
          },
          {
            title: "Business Account Management using Bulk Upload",
            url: "bulk-account-management#business-bulk-account-management",
          },
        ],
        expanded: false,
      },
    ],
    expanded: true,
  },
  {
    title: "External Developer",
    url: "",
    children: [{ title: "Confirmation of Payee Response Service", url: "inbound" }],
    expanded: true,
  },
];

export const documentationNordicsLinks = (): IDocsLink[] => [
  {
    title: "Getting Started",
    url: "",
    children: [
      { title: "Developer Onboarding", url: "nordics/developer_onboarding" },
      { title: "App Management", url: "nordics/developer_apps" },
      { title: "OAuth Authentication", url: "nordics/oauth_authentication" },
    ],
    expanded: true,
  },
  {
    title: "Internal Developer",
    url: "",
    children: [{ title: "Confirmation of Payee Requestor Service", url: "nordics/cop_out" }],
    expanded: true,
  },
  {
    title: "External Developer",
    url: "",
    children: [
      { title: "Confirmation of Account Responder Service", url: "nordics/cop_account" },
      { title: "Confirmation of Payee Responder Service", url: "nordics/cop_in" },
    ],
    expanded: true,
  },
];

const DocumentationMenu = () => {
  const router = useRouter();
  const pathname = usePathname();
  const { fileName } = useParams<{ fileName: string[] }>();

  const isNordics = pathname?.includes("/nordics");

  const defaultFileName = fileName?.length ? fileName[fileName?.length - 1] : "developer_onboarding";
  const currentFileName = isNordics ? "nordics/" + defaultFileName : defaultFileName;

  const { tenantName, isClientApp, hideRequestService } = useEnvVariables();

  const isQnbApp = isQnb(tenantName);
  const isKbcApp = isKbc(tenantName) || isAub(tenantName);

  const [links, setLinks] = useState(
    isNordics ? documentationNordicsLinks() : documentationLinks(isQnbApp, isKbcApp, isClientApp, hideRequestService),
  );
  const [currentChild, setCurrentChild] = useState(currentFileName);
  const [currentItem, setCurrentItem] = useState(currentFileName);

  const toggleExpanded = (i: number, innerIndex: number) => {
    const newData = [...links];

    if (!innerIndex) {
      newData[i].expanded = !links[i].expanded;
      setLinks(newData);
    } else {
      newData[i].children![innerIndex - 1].expanded = !links[i].children![innerIndex - 1].expanded;
      setLinks(newData);
    }
  };

  const handleChildrenClick = (child: IDocsLink) => {
    setCurrentItem(child.title);
    router.push(`${Routes.DOCUMENTATION}/${child.url}`);
  };

  return (
    <List sx={{ width: "300px", bgcolor: "background.secondary", display: { xs: "none", sm: "block" } }}>
      {links.map((v, i) => (
        <Fragment key={v.title}>
          <ListItemButton onClick={() => setCurrentChild(currentChild === v.title ? "" : v.title)} sx={{ px: "24px" }}>
            <ListItemText primary={<Typography variant="h6">{v.title}</Typography>} />
            {/* {(v.children?.length ?? 0) > 0 &&
              (v.expanded ? (
                <ExpandLess onClick={() => toggleExpanded(i, 0)} />
              ) : (
                <ExpandMore onClick={() => toggleExpanded(i, 0)} />
              ))} */}
          </ListItemButton>
          {/* <Collapse in={v.expanded} timeout="auto" unmountOnExit> */}
          <List component="div" disablePadding>
            {v.children?.map((child, index) => (
              <Fragment key={child.title}>
                <ListItemButton
                  data-testid={`${v.title.replace(" ", "-").toLowerCase()}-children`}
                  key={child.title}
                  sx={{
                    pl: 4,
                    py: "2px",
                    backgroundColor: currentItem === child.title ? "rgba(0, 0, 0, 0.04)" : "initial",
                  }}
                  onClick={() => handleChildrenClick(child)}
                >
                  <ListItemText
                    primaryTypographyProps={{ variant: "body2", color: "primary.main" }}
                    primary={child.title}
                  />
                  {(child.children?.length ?? 0) > 0 &&
                    (child.expanded ? (
                      <ExpandLess sx={{ marginLeft: "-4px" }} onClick={() => toggleExpanded(i, index + 1)} />
                    ) : (
                      <ExpandMore sx={{ marginLeft: "-4px" }} onClick={() => toggleExpanded(i, index + 1)} />
                    ))}
                  {child?.secure && (
                    <ListItemIcon sx={{ minWidth: "30px" }}>
                      <LockRounded fontSize="small" />
                    </ListItemIcon>
                  )}
                </ListItemButton>
                <Collapse in={child.expanded} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {child.children?.map(c => (
                      <ListItemButton
                        data-testid={`${c.title.replace(" ", "-").toLowerCase()}-children`}
                        key={c.title}
                        sx={{
                          pl: 7,
                          py: "2px",
                          backgroundColor: currentItem === c.title ? "rgba(0, 0, 0, 0.04)" : "initial",
                        }}
                        onClick={() => handleChildrenClick(c)}
                      >
                        <ListItemText
                          primaryTypographyProps={{ variant: "body2", color: "primary.main" }}
                          primary={c.title}
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              </Fragment>
            ))}
          </List>
          {/* </Collapse> */}
        </Fragment>
      ))}
    </List>
  );
};

export default DocumentationMenu;
