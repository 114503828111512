"use client";

import React from "react";
import Link from "next/link";
import { usePathname } from "next/navigation";

import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
  Box,
  Container,
  Link as MuiLink,
  AppBar,
  Toolbar,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import Logo from "@/components/common/Logo";
import { documentationLinks } from "@/components/publicPages/Documentation/DocumentationMenu";
import Account from "../Account";
import { useGetUserQuery } from "@/hooks/reactQueryHooks/userHooks";
import useMenuState from "@/hooks/useMenuState";
import { useEnvVariables } from "@/config/EnvVariablesProvider";
import { useCountry } from "@/config/CountryProvider";
import { authUrl, getClientType } from "@/helpers";
import { findNearestAllowedRoute, permissionsByPage } from "@/helpers/permissionsByPage";
import { isQnb, isJpm, isKbc, isAub } from "@/helpers/constants";
import { button, signUpButton, menuStyles } from "../styles";
import { CountryCode, EnvVariables, Routes } from "@/types";

const menuItems = (envs: EnvVariables) => [
  { title: "Developer", url: authUrl(envs, false) },
  { title: "Operations", url: authUrl(envs, true) },
];

const getMenu = (hideProducts: boolean, hideDocuments: boolean, hideContacts: boolean) =>
  [
    { title: "Products", url: Routes.PRODUCTS, hide: hideProducts },
    { title: "Documentation", url: Routes.DOCUMENTATION, hide: hideDocuments },
    { title: "Contact", url: Routes.CONTACT_US, hide: hideContacts },
  ].filter(v => !v.hide);

const MenuItemButton = ({ text, url }: { text: string; url: string }) => {
  const pathname = usePathname();

  return (
    <Button
      key={text}
      component={Link}
      href={url}
      sx={{
        borderRadius: 0,
        borderBottom: theme =>
          `/${pathname?.split("/")[1]}` === url ? `2px solid ${theme.palette.primary.main}` : "initial",
        mx: "16px",
        color: "text.primary",
        "&:hover": {
          color: "primary.main",
        },
      }}
    >
      {text}
    </Button>
  );
};

type Props = {
  isDocumentationPage?: boolean;
};

const Header: React.FC<Props> = ({ isDocumentationPage }) => {
  const envs = useEnvVariables();
  const hideProducts = !!envs?.hideProducts;
  const hideDocuments = !!envs?.hideDocuments;
  const hideContacts = !!envs?.hideContacts;
  const hideSignUp = !!envs?.hideSignUp;
  const hideSignIn = !!envs?.hideSignIn;
  const onlyAdminSignIn = envs?.onlyAdminSignIn ?? "";
  const tenantName = envs?.tenantName ?? "";

  const isQnbApp = isQnb(tenantName);
  const isKbcApp = isKbc(tenantName);
  const isAubApp = isAub(tenantName);
  const isJpmApp = isJpm(tenantName);

  const { data: user } = useGetUserQuery();

  const { country } = useCountry();
  const goToDashboardUrl =
    getClientType() === "copadmin-spa"
      ? findNearestAllowedRoute(permissionsByPage(country as CountryCode, envs, user))
      : Routes.APPS;

  const [anchorEl, handleClick, handleClose] = useMenuState();
  const [signInMenu, handleSignInClick, handleSignInClose] = useMenuState();

  const mobileMenu = (
    <Menu anchorEl={anchorEl} open={!!anchorEl} onClick={handleClose} onClose={handleClose}>
      <MenuItem onClick={handleClose}>
        <MuiLink component={Link} href="/" sx={{ textDecoration: "none", width: "100%" }}>
          Home
        </MuiLink>
      </MenuItem>
      {!hideProducts && (
        <MenuItem onClick={handleClose}>
          <MuiLink component={Link} href={Routes.PRODUCTS} sx={{ textDecoration: "none", width: "100%" }}>
            Products
          </MuiLink>
        </MenuItem>
      )}
      {!hideDocuments && (
        <MenuItem onClick={handleClose}>
          <MuiLink component={Link} href={Routes.DOCUMENTATION} sx={{ textDecoration: "none", width: "100%" }}>
            Documentation
          </MuiLink>
        </MenuItem>
      )}
      {!hideContacts && (
        <MenuItem onClick={handleClose}>
          <MuiLink component={Link} href={Routes.CONTACT_US} sx={{ textDecoration: "none", width: "100%" }}>
            Contact
          </MuiLink>
        </MenuItem>
      )}
      {isDocumentationPage &&
        documentationLinks(isQnbApp, isKbcApp || isAubApp, false, false).map(v => {
          if (v.children?.length === 0) {
            return (
              <MenuItem key={v.title} onClick={handleClose}>
                <MuiLink component={Link} href={v.url} sx={{ textDecoration: "none", width: "100%" }}>
                  {v.title}
                </MuiLink>
              </MenuItem>
            );
          }
          return (
            <Box key={v.title}>
              <Typography variant="h6" p="8px 16px " bgcolor="background.secondary">
                {v.title}
              </Typography>
              {v.children?.map(child => (
                <MenuItem key={child.title} onClick={handleClose}>
                  <MuiLink component={Link} href={child.url} sx={{ textDecoration: "none", width: "100%" }}>
                    {child.title}
                  </MuiLink>
                </MenuItem>
              ))}
            </Box>
          );
        })}
    </Menu>
  );

  const menu = (
    <Stack direction="row" sx={{ height: "100%", px: "32px", display: { xs: "none", sm: "flex" } }}>
      {getMenu(hideProducts, hideDocuments, hideContacts).map(v => (
        <MenuItemButton key={v.url} text={v.title} url={v.url} />
      ))}
    </Stack>
  );

  const menuSignInType = (
    <Menu
      anchorEl={signInMenu}
      open={!!signInMenu}
      onClick={handleSignInClose}
      onClose={handleSignInClose}
      sx={{ ...menuStyles, width: "200px" }}
    >
      {envs &&
        menuItems(envs).map(({ title, url }) => {
          return (
            <MenuItem key={title} sx={{ padding: "0px" }} onClick={handleSignInClose}>
              <MuiLink
                component={Link}
                href={url}
                variant="h6"
                color="text.secondary"
                sx={{ textDecoration: "none", width: "100%", padding: "6px 16px" }}
              >
                {title}
              </MuiLink>
            </MenuItem>
          );
        })}
    </Menu>
  );

  const authButtons = (
    <Stack direction="row" position="relative">
      {user && (
        <Stack direction="row" alignItems="center">
          <Button component={Link} href={goToDashboardUrl} color="inherit" sx={{ ...button, mr: "8px" }}>
            Go to Dashboard
          </Button>
          <Typography lineHeight="32px" pr="8px" color="#E0E0E0">
            |
          </Typography>
          <Account admin={getClientType() === "copadmin-spa"} />
        </Stack>
      )}
      {!user && (
        <Stack direction="row" alignItems="center">
          {!onlyAdminSignIn && !hideSignIn && (
            <Button href="" color="primary" sx={button} onClick={handleSignInClick}>
              Sign In
            </Button>
          )}
          {onlyAdminSignIn && !hideSignIn && (
            <Button href={authUrl(envs, true)} color="primary" sx={button}>
              Sign In
            </Button>
          )}
          {menuSignInType}
          {!(isJpmApp || hideSignUp) && (
            <>
              <Typography lineHeight="32px" pr="8px" color="#E0E0E0">
                |
              </Typography>
              <Button component={Link} href={Routes.REGISTER} color="primary" variant="contained" sx={signUpButton}>
                Sign Up
              </Button>
            </>
          )}
        </Stack>
      )}
    </Stack>
  );

  return (
    <AppBar
      position="static"
      elevation={0}
      sx={{
        borderBottomWidth: 1,
        borderBottomStyle: "solid",
        borderBottomColor: "divider",
        bgcolor: "common.white",
        color: "text.primary",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Toolbar disableGutters sx={{ width: "100%", display: "flex", alignItems: "stretch" }}>
        <Container maxWidth="xl" sx={{ width: "100%", display: "flex", alignItems: "stretch" }}>
          <Stack direction="row" justifyContent="space-between" alignItems="stretch" width="100%">
            <Box sx={{ display: { xs: "flex", sm: "none" }, mr: "8px" }}>
              <IconButton onClick={handleClick}>
                <MenuIcon />
              </IconButton>
              {mobileMenu}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Logo />
            </Box>
            <>
              {menu}
              {authButtons}
            </>
          </Stack>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
